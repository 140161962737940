<template>
  <b-card title="THEME CONFIG">

    <pre v-if="debugMode">
      {{ getThemeConfig }}
    </pre>

    test color
    <b-button variant="leanpay-primary">
      SSDSDSSD
    </b-button>
  </b-card>
</template>

<script>
import { BCard, BCardText } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardText,
  },
}
</script>

<style>

</style>
